<template>
  <v-layout
    v-if="!items.length"
    justify-center
  >
    No orders
  </v-layout>

  <vbt-table
    v-else
    :headers="headers"
    :items="items"
  >
    <template #orderId="{ item: { orderId: id } }">
      <v-layout align-center>
        <router-link
          v-if="id"
          :to="{
            name: 'order',
            params: { id },
          }"
          class="blue--text darken-1 mr-1"
        >
          {{ id }}
        </router-link>

        <template v-else>
          -
        </template>
      </v-layout>
    </template>

    <template #accountId="{ item: { accountId } }">
      {{ accountNameById[accountId] }}
    </template>

    <template #msbcInvoiceNumber="{ item: { msbcInvoiceNumber } }">
      {{ msbcInvoiceNumber || '-' }}
    </template>

    <template #date="{ item: { dateCreated, dateReleased } }">
      {{ formatDisplayDate(dateCreated, { dateFormat: 'yyyy-MM-dd' }) }}
      / {{ formatDisplayDate(dateReleased, { dateFormat: 'yyyy-MM-dd' }) }}
    </template>

    <template #amount="{ item }">
      {{ formatNumber(item.amount) }}
      / {{ formatNumber(item.taxAmount) }}
      / {{ formatNumber(item.amountWithTax) }}
    </template>

    <template #status="{ item: { status } }">
      {{ msbcOrderStatusesByTypeId[status] }}
    </template>

    <template #status="{ item }">
      <v-tooltip top>
        <template #activator="{ on }">
          <span v-on="item.status === 1 ? on : undefined">
            {{ msbcOrderStatusesByTypeId[item.status] }}
          </span>
        </template>

        {{ formatDisplayDate(item.dateReleased) }}
      </v-tooltip>
    </template>
  </vbt-table>
</template>

<script>
import { mapGetters } from 'vuex';

import { formatDisplayDate, formatNumber } from '@helpers';

export default {
  name: 'MsbcOrders',

  props: {
    items: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      default: 'sales',
    },
  },

  computed: {
    ...mapGetters('accounts', ['accountNameById']),

    ...mapGetters('enums', ['msbcOrderStatusesByTypeId']),

    headers() {
      return [
        { text: 'Order ID', value: 'orderId' },
        { text: this.type === 'sales' ? 'Customer' : 'Provider', value: 'accountId' },
        { text: 'MSBC Order Number', value: 'msbcOrderNumber' },
        { text: 'Invoiced in', value: 'msbcInvoiceNumber' },
        { text: 'Created/Released Date', value: 'date' },
        { text: 'Amount/Tax/With Tax', value: 'amount' },
        { text: 'Status', value: 'status' },
      ];
    },
  },

  methods: {
    formatDisplayDate,
    formatNumber,
  },
};
</script>
