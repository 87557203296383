<template>
  <v-layout
    v-if="!items.length"
    justify-center
  >
    No available invoices
  </v-layout>

  <vbt-table
    v-else
    :headers="headers"
    :items="items"
  >
    <template #accountId="{ item: { accountId } }">
      {{ accountNameById[accountId] }}
    </template>

    <template #msbcInvoiceNumber="{ item }">
      <v-layout column>
        {{ item.msbcInvoiceNumber || '-' }}
        <span v-if="item.msbcPostedInvoiceNumber">
          {{ item.msbcPostedInvoiceNumber }}
        </span>
      </v-layout>
    </template>

    <template #accountId="{ item: { accountId } }">
      {{ accountNameById[accountId] }}
    </template>

    <template #dateCreated="{ item: { dateCreated, datePosted } }">
      {{ formatDisplayDate(dateCreated) }} / {{ formatDisplayDate(datePosted) }}
    </template>

    <template #date="{ item: { date } }">
      {{ formatDisplayDate(date, { dateFormat: 'yyyy-MM-dd' }) }}
    </template>

    <template #amount="{ item }">
      {{ formatNumber(item.amount) }}
      / {{ formatNumber(item.taxAmount) }}
      / {{ formatNumber(item.amountWithTax) }}
    </template>

    <template #type="{ item: { type } }">
      {{ msbcInvoiceTypesByTypeId[type] }}
    </template>

    <template #status="{ item }">
      <v-tooltip top>
        <template #activator="{ on }">
          <span v-on="item.status === 1 ? on : undefined">
            {{ msbcInvoiceStatusesByStatusKey[item.status] }}
          </span>
        </template>

        {{ formatDisplayDate(item.datePosted) }}
      </v-tooltip>
    </template>
  </vbt-table>
</template>

<script>
import { mapGetters } from 'vuex';

import { formatDisplayDate, formatNumber } from '@helpers';

const headers = Object.freeze([
  { text: 'Account', value: 'accountId' },
  { text: 'MSBC Invoice Number', value: 'msbcInvoiceNumber' },
  { text: 'Date Created / Posted', value: 'dateCreated' },
  { text: 'Date', value: 'date' },
  { text: 'Amount/Tax/With Tax', value: 'amount' },
  { text: 'Type', value: 'type' },
  { text: 'Status', value: 'status' },
]);

export default {
  name: 'IrMsbcInvoicesList',

  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      headers,
    };
  },

  computed: {
    ...mapGetters('enums', ['msbcInvoiceTypesByTypeId', 'msbcInvoiceStatusesByStatusKey']),

    ...mapGetters('accounts', ['accountNameById']),
  },

  methods: {
    formatDisplayDate,
    formatNumber,
  },
};
</script>
