<template>
  <v-btn
    :color="integrationIsActive ? 'success' : 'error'"
    :loading="loading"
    height="35px"
    width="100%"
    outlined
    small
    @click="processIntegration(
      integrationIsActive ? integrationActions.stop : integrationActions.start
    )"
  >
    <v-layout
      justify-center
      align-center
    >
      <strong>
        {{ integrationIsActive ? 'Integration is active' : 'Click to start integration' }}
      </strong>
      <v-icon>
        {{ integrationIsActive ? 'stop' : 'play_arrow' }}
      </v-icon>
    </v-layout>
  </v-btn>
</template>

<script>
import { wrapToLoadingFn } from '@helpers';

import { MSBCApiService } from '../_services/msbc.api.service';

export default {
  name: 'IrMsbcIntegration',

  data() {
    return {
      loading: true,
      integrationIsActive: false,
      integrationActions: {
        start: 'start',
        stop: 'stop',
      },
    };
  },

  created() {
    this.getIntegrationStatus();
  },

  methods: {
    processIntegration(type) {
      const isStart = type === this.integrationActions.start;
      const text = isStart ? 'Are you sure to start integration?' : 'Are you sure to stop integration?';
      const req = isStart ? MSBCApiService.startIntegration : MSBCApiService.stopIntegration;

      this.$VBlackerTheme.alert.warning({ text }, () => {
        this.loading = true;

        this.wrapToLoadingFn({
          req,
          withLoadingSpinner: false,
          onSuccess: () => this.getIntegrationStatus(),
        });
      });
    },

    getIntegrationStatus() {
      this.wrapToLoadingFn({
        req: MSBCApiService.getIntegrationStatus,
        loadingFlagName: 'loading',
        onSuccess: (r) => {
          this.integrationIsActive = r;
        },
      });
    },

    wrapToLoadingFn,
  },
};
</script>
