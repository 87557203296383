var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.items.length)?_c('v-layout',{attrs:{"justify-center":""}},[_vm._v(" No orders ")]):_c('vbt-table',{attrs:{"headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"orderId",fn:function(ref){
var id = ref.item.orderId;
return [_c('v-layout',{attrs:{"align-center":""}},[(id)?_c('router-link',{staticClass:"blue--text darken-1 mr-1",attrs:{"to":{
          name: 'order',
          params: { id: id },
        }}},[_vm._v(" "+_vm._s(id)+" ")]):[_vm._v(" - ")]],2)]}},{key:"accountId",fn:function(ref){
        var accountId = ref.item.accountId;
return [_vm._v(" "+_vm._s(_vm.accountNameById[accountId])+" ")]}},{key:"msbcInvoiceNumber",fn:function(ref){
        var msbcInvoiceNumber = ref.item.msbcInvoiceNumber;
return [_vm._v(" "+_vm._s(msbcInvoiceNumber || '-')+" ")]}},{key:"date",fn:function(ref){
        var ref_item = ref.item;
        var dateCreated = ref_item.dateCreated;
        var dateReleased = ref_item.dateReleased;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDate(dateCreated, { dateFormat: 'yyyy-MM-dd' }))+" / "+_vm._s(_vm.formatDisplayDate(dateReleased, { dateFormat: 'yyyy-MM-dd' }))+" ")]}},{key:"amount",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.amount))+" / "+_vm._s(_vm.formatNumber(item.taxAmount))+" / "+_vm._s(_vm.formatNumber(item.amountWithTax))+" ")]}},{key:"status",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('span',_vm._g({},item.status === 1 ? on : undefined),[_vm._v(" "+_vm._s(_vm.msbcOrderStatusesByTypeId[item.status])+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.formatDisplayDate(item.dateReleased))+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }