import { mapState, mapActions, mapGetters } from 'vuex';

import { usePagination, useSearch, useDataLoading } from '@mixins/factories';

import IrMsbcIntegration from '../../_components/IrMsbcIntegration.vue';
import IrMsbcInvoicesList from '../../_components/IrMsbcInvoicesList.vue';
import MsbcOrdersList from '../../_components/MsbcOrdersList.vue';

const dataLoadingOptions = { getterName: 'getMsbcData' };
const paginationOptions = { itemsName: 'msbcItems' };
const searchOptions = {
  initialSearchDataStates: ['orderId', 'accountId', 'dates', 'number'],
  customTypes: {
    accountId: 'number',
    dates: 'array',
  },
};

const tabs = Object.freeze([
  { text: 'Sales Orders', key: 'salesOrders' },
  { text: 'Purchase Orders', key: 'purchaseOrders' },
  { text: 'Invoices', key: 'invoices' },
]);

const DATA_TYPES = Object.freeze({
  salesOrders: { data: 'msbcSalesOrders', action: 'getMsbcSalesOrders' },
  purchaseOrders: { data: 'msbcPurchaseOrders', action: 'getMsbcPurchaseOrders' },
  invoices: { data: 'msbcInvoices', action: 'getMsbcInvoices' },
});

export default {
  name: 'IrMsbc',

  components: {
    IrMsbcIntegration,
    IrMsbcInvoicesList,
    MsbcOrdersList,
  },

  mixins: [
    useDataLoading(dataLoadingOptions),
    usePagination(paginationOptions),
    useSearch(searchOptions),
  ],

  data() {
    return {
      tab: 'salesOrders',

      tabs,
    };
  },

  computed: {
    ...mapState('msbc', {
      msbcItems(state) {
        return state[DATA_TYPES[this.tab].data];
      },
    }),

    ...mapGetters('accounts', ['accountsOptions', 'clientsOptions', 'providersOptions']),

    searchFieldsConfig() {
      const fieldFlex = this.tab === 'invoices' ? 'lg4' : 'lg3';
      const fields = [];

      if (this.tab === 'invoices') {
        fields.push(
          {
            value: 'accountId',
            items: this.accountsOptions,
            label: 'Account',
            type: 'combobox',
            flex: fieldFlex,
          },
        );
      } else {
        fields.push(
          {
            value: 'orderId', label: 'Order ID', type: 'text', flex: fieldFlex,
          },
          {
            value: 'accountId',
            items: this.tab === 'salesOrders'
              ? this.clientsOptions
              : this.providersOptions,
            label: this.tab === 'salesOrders' ? 'Customer' : 'Provider',
            type: 'combobox',
            flex: fieldFlex,
          },
        );
      }

      fields.push(
        {
          value: 'dates', label: 'Date Range', type: 'daterange', flex: fieldFlex,
        },
        {
          value: 'number', label: 'Order or Invoice or Statement number', type: 'text', flex: fieldFlex,
        },
      );

      return fields;
    },
  },

  watch: {
    tab() {
      this.onResetSearch();
    },
  },

  created() {
    this.getAccounts({ activeOnly: true });
  },

  methods: {
    ...mapActions('accounts', ['getAccounts']),

    ...mapActions('msbc', {
      async getMsbcData(dispatch, params) {
        this.isLoading = true;
        this.loadingError.show = false;

        try {
          await dispatch(DATA_TYPES[this.tab].action, params);

          return Promise.resolve();
        } catch (e) {
          Object.assign(this, {
            loadingError: { show: true, text: e },
          });
          return Promise.reject();
        } finally {
          this.isLoading = false;
        }
      },
    }),

    changeAccountsState(value) {
      this.getAccounts({ activeOnly: !value });
    },

    refreshData() {
      this.allowGettingData = true;
    },
  },
};
