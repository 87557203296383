import HTTP from '@api';

import { generateXHRError } from '@helpers';

export const MSBCApiService = {
  async getIntegrationStatus() {
    try {
      const { data } = await HTTP.get('msbc/integration/status');

      return data.isActive ?? false;
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },

  async startIntegration() {
    try {
      return await HTTP.post('msbc/integration/start');
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },

  async stopIntegration() {
    try {
      return await HTTP.post('msbc/integration/stop');
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },
};

export default {};
