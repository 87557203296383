var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.items.length)?_c('v-layout',{attrs:{"justify-center":""}},[_vm._v(" No available invoices ")]):_c('vbt-table',{attrs:{"headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"accountId",fn:function(ref){
var accountId = ref.item.accountId;
return [_vm._v(" "+_vm._s(_vm.accountNameById[accountId])+" ")]}},{key:"msbcInvoiceNumber",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{attrs:{"column":""}},[_vm._v(" "+_vm._s(item.msbcInvoiceNumber || '-')+" "),(item.msbcPostedInvoiceNumber)?_c('span',[_vm._v(" "+_vm._s(item.msbcPostedInvoiceNumber)+" ")]):_vm._e()])]}},{key:"dateCreated",fn:function(ref){
var ref_item = ref.item;
var dateCreated = ref_item.dateCreated;
var datePosted = ref_item.datePosted;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDate(dateCreated))+" / "+_vm._s(_vm.formatDisplayDate(datePosted))+" ")]}},{key:"date",fn:function(ref){
var date = ref.item.date;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDate(date, { dateFormat: 'yyyy-MM-dd' }))+" ")]}},{key:"amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.amount))+" / "+_vm._s(_vm.formatNumber(item.taxAmount))+" / "+_vm._s(_vm.formatNumber(item.amountWithTax))+" ")]}},{key:"type",fn:function(ref){
var type = ref.item.type;
return [_vm._v(" "+_vm._s(_vm.msbcInvoiceTypesByTypeId[type])+" ")]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},item.status === 1 ? on : undefined),[_vm._v(" "+_vm._s(_vm.msbcInvoiceStatusesByStatusKey[item.status])+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.formatDisplayDate(item.datePosted))+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }